import React, { Component } from "react"
import { graphql, navigate, Link } from "gatsby"
import styled from "@emotion/styled"
import HomepageLayout from "../components/layout/index.js"
import Row from "../components/layout/row.js"
import Paginate from "../components/common/paginate"
import { toLocalDate } from "../helpers"
import { Helmet } from "react-helmet"

const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
  ${props => props.theme.tablet} {
    margin: 0;
  }
`

const MyDate = styled.div`
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 0.5rem;
`

const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
`

const LinkWrapper = styled.div`
  flex-basis: 50%;
  margin-bottom: 5rem;
  ${props => props.theme.tablet} {
    flex-basis: 100%;
  }
`

const PostInfo = styled.div`
  display: flex;
  ${props => props.theme.tablet} {
    flex-direction: column;
  }
`

const StyledLink = styled(Link)`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  border-bottom: 1px solid ${props => props.theme.colors.line};
  &:hover {
    span {
      color: ${props => props.theme.colors.activeLink};
    }
  }

  ${props => props.theme.tablet} {
    margin-bottom: 1rem;
  }
`

const LinkInsideWrapper = styled.div`
  height: 100%;

  padding: 0 1rem;
  a {
    color: black;
    font-size: 0.9rem;
    text-align: justify;
  }

  ${props => props.theme.tablet} {
    padding: 0;
  }
`

const PostImage = styled.img`
  max-width: 200px;
  max-height: 150px;
  margin-left: 1rem;
`

const ImageWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`
const ExcerptWrapper = styled.div`
  color: ${props => props.theme.colors.secondary};
  ${props => props.theme.tablet} {
    margin-bottom: 1rem;
  }
`

class IndexPage extends Component {
  handlePageClick = data => {
    if (data.selected + 1 === 1) {
      navigate(`/news/`)
      return
    }
    navigate(`/news/page/${data.selected + 1}`)
  }

  render() {
    const {
      data,
      pageContext: { pageNumber, totalPages },
    } = this.props
    return (
      <HomepageLayout>
        <Helmet>
          <title>{`Новости Фонда, страница ${parseInt(pageNumber) + 1}`}</title>
          <meta
            name="description"
            content="Новости Фонда Защиты Прав Граждан участников строительства в ПК"
          />
        </Helmet>
        <Row margin="2rem auto">
          <h1>Новости Фонда</h1>
          <LinksWrapper>
            {data &&
              data.wordPress &&
              data.wordPress.posts.nodes.map(post => (
                <LinkWrapper key={post.id}>
                  <LinkInsideWrapper>
                    <StyledLink to={`/news/${post.slug}`} key={post.id}>
                      <MyDate>{toLocalDate(post.date)}</MyDate>
                      <Title>
                        <span
                          dangerouslySetInnerHTML={{ __html: post.title }}
                        />
                      </Title>
                      <PostInfo>
                        <ExcerptWrapper
                          dangerouslySetInnerHTML={{ __html: post.excerpt }}
                        />
                        <ImageWrapper>
                          {post.featuredImage && (
                            <PostImage
                              src={`${post.featuredImage.sourceUrl}`}
                            />
                          )}
                        </ImageWrapper>
                      </PostInfo>
                    </StyledLink>
                  </LinkInsideWrapper>
                </LinkWrapper>
              ))}
          </LinksWrapper>
          <Paginate
            totalPages={totalPages}
            handlePageClick={this.handlePageClick}
            pageNumber={parseInt(pageNumber)}
          />
        </Row>
      </HomepageLayout>
    )
  }
}

export default IndexPage

export const query = graphql`
  query GET_POSTS($ids: [ID]) {
    wordPress {
      posts(where: { in: $ids }) {
        nodes {
          id
          title
          uri
          slug
          excerpt
          date
          featuredImage {
            sourceUrl(size: MEDIUM)
          }
        }
      }
    }
  }
`
